import { ElementType } from "react";

interface ICard {
    icon: ElementType;
    title: string;
}

export function CardEntregavel({ title, icon: Icon }: ICard) {
    return (
        <div className='w-full h-[20vh] md:w-[25vw] lg:w-[15vw] min-h-fit p-4 flex flex-col gap-4 items-center justify-center bg-white rounded-3xl shadow-2xl'>
            <Icon size={38} color='red' />
            <h2 className='uppercase text-center font-semibold'>{title}</h2>
        </div>
    )
}