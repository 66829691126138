import { Hero } from "../components/Hero/Hero";
import { Navbar } from "../components/Navbar";

export function Header() {
    return (
        <div id="home">
            <Navbar />
            <Hero />
        </div>
    )
}