import { useState } from 'react'

import { Dialog } from '@headlessui/react';

import LMSE from '../../assets/logos/logo-leandro_02.png';
import AMPAR from '../../assets/logos/logo_ampar.png'

import { List, X } from '@phosphor-icons/react';

const navList = [
    { name: 'Home', href: 'home' },
    { name: 'Apresentação Institucional', href: 'video' },
    { name: 'Catálogo de Cursos', href: 'courses' },
    { name: 'Cursos Oferecidos', href: 'offer' },
    { name: 'Financeiro', href: 'finance' },
    { name: 'Inscrições', href: 'vestibular' },
    { name: 'Institucional', href: 'institucional' },
    { name: 'Contato', href: 'contact' },
]

export function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-white shadow-lg shadow-stone-300 fixed w-screen z-10">
            {/* Desktop Nav */}
            <nav className="flex items-center justify-around p-6 lg:px-8 w-full" aria-label="Global">
                {/* Logo */}
                <div className="flex items-center">
                    <a href="https://www.lmse.com.br">
                        <img className="w-[9vw]" src={LMSE} alt="" />
                    </a>
                    <a href="https://amparzonadamata.org.br/">
                        <img className="h-[5vh]" src={AMPAR} alt="" />
                    </a>
                </div>
                {/* Botão Hamburger */}
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <List size={32} className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navList.map((item) => (
                        <a
                            key={item.name}
                            href={`#${item.href}`}
                            className="text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200 hover:text-emerald-500 px-2.5 py-1 rounded-2xl transition duration-300">
                            {item.name}
                        </a>

                    ))}
                </div>
            </nav>
            {/* Mobile Sidebar */}
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-end">
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <X size={32} className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {
                                    navList.map((item) => (
                                        <a
                                            key={item.name}
                                            href={`#${item.href}`}
                                            onClick={() => setMobileMenuOpen(false)}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-emerald-500">
                                            {item.name}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header >
    )
}
