import about from '../assets/fundos/about-pattern.png'

interface IAbout {
    title?: string;
    content?: string;
}

function Content({ content }: IAbout) {
    return (
        <p className='text-white'>{content}</p>
    )
}

function Title({ title }: IAbout) {
    return (
        <h1 className='text-white font-bold text-2xl'>{title}</h1>
    )
}

export function About() {
    return (
        <div id='courses' className='h-fit max-w-screen'>
            <img className='absolute right-0 -z-10 h-full lg:h-auto object-cover' src={about} alt="" />
            <div className='max-w-[85vw] bg-red-600 pb-8 rounded-br-[10rem] md:rounded-br-[18rem] flex flex-col items-center gap-4'>
                <h1 className='relative -top-10 max-w-fit bg-red-600 border-white border-8 text-white text-center text-xl md:text-3xl lg:text-5xl font-sans font-bold py-4 px-8 md:py-4 md:px-20 rounded-2xl'>Catálogo de Cursos</h1>

                <div className='flex flex-col gap-8 mx-10 md:items-center'>
                    <div className='max-w-prose md:w-[20vw]'>
                        <Title title='Cursos livres' />
                        <Content content='Agronegócio;' />
                        <Content content='Educação;' />
                        <Content content='Empreendedorismo;' />
                        <Content content='e Muito mais!' />
                    </div>
                    <div className='max-w-prose md:w-[20vw]'>
                        <Title title='Graduação tecnológica' />
                        <Content content='Gestão Pública;' />
                        <Content content='Educação;' />
                        <Content content='Negócios;' />
                        <Content content='Saúde;' />
                        <Content content='Tecnologia.' />
                    </div>
                    <div className='max-w-prose md:w-[20vw]'>
                        <Title title='Pós-Graduação' />
                        <Content content='Arquitetura e urbanismo;' />
                        <Content content='Direito;' />
                        <Content content='Educação;' />
                        <Content content='Engenharia;' />
                        <Content content='Negócios;' />
                        <Content content='Odontologia;' />
                        <Content content='Saúde;' />
                        <Content content='e Tecnologia.' />
                    </div>
                    <div className='max-w-prose md:w-[20vw]'>
                        <Title title='Semanas de estudos internacionais na Europa' />
                        <Content content='Espanha;' />
                        <Content content='e Portugal' />
                    </div>
                </div>

                <a href="#offer" className='bg-red-600 border-white border-8 text-white text-center text-lg md:text-xl lg:text-3xl font-sans font-bold py-2 px-6 md:py-2 md:px-10 rounded-2xl hover:brightness-110'>Saiba mais</a>
            </div>
        </div>
    )
}