import img6 from '../assets/team/img6.png'
import img7 from '../assets/team/img7.png'
import img8 from '../assets/team/img8.png'
import img9 from '../assets/team/img9.png'
import img10 from '../assets/team/img10.png'

import { Header } from "../components/Header";
import { Title } from "../components/Title"
import { List } from "../components/List"

interface ISquare {
    position: "RIGHT" | "LEFT";
}

function Square({ position }: ISquare) {

    const style = "absolute -z-10 w-[40vw] h-[25vh] rounded-3xl"

    return <div className={
        position === "RIGHT" ?
            `${style} bg-green-700` :
            `${style} bg-red-600`
    }></div>
}

export function Ungraduation() {
    return (
        <div className="max-w-screen mx-5">
            <Header title="Pós-Graduação" />

            {/* Arquitetura e Urbanismo */}
            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Arquitetura e Urbanismo' />
                    <List list={['Cidades Inteligentes e Sustentáveis', 'Construções Sustentáveis', 'Legalização de Imóveis e Aprovação de Projetos']} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img6} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>

            {/* Direito */}
            <div id="row" className="flex flex-col md:grid grid-cols-2 gap-4">
                <aside className='flex items-end justify-center'>
                    <img src={img7} alt="" />
                    <Square position='LEFT' />
                </aside>
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Direito' />
                    <List list={['Direito, Ética Empresarial e Compliance', 'Direito, Políticas Públicas e Controle Externo', 'Ciências Criminais', 'Tutela Civil dos Direitos Difusos',]} />
                </aside>
            </div>

            {/* Educação */}
            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Educação' />
                    <List list={['Alfabetização e Letramento', 'Avaliação em Educação Básica', 'Educação Infantil', 'Formação de Docentes para o Ensino em Enfermagem em Nivel Técnico e Superior', 'Formação de Docentes para o Ensino Superior', 'Gramática e Texto da Língua Portuguesa', 'Língua Brasileira de Sinais - LIBRAS', 'Neurociências da Aprendizagem', 'Pedagogia Hospitalar', 'Pedagogias da Infância - Fundamentos, Metodologias e Procedimentos', 'Psicomotricidade', 'Psicopedagogia', 'Psicopedagogia Clínica e Institucional', 'Transtorno do Espectro do Autismo (TEA)', 'Transtornos de Aprendizagem']} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img8} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>

            {/* Engenharia */}
            <div id="row" className="flex flex-col md:grid grid-cols-2 gap-4">
                <aside className='flex items-end justify-center'>
                    <img src={img9} alt="" />
                    <Square position='LEFT' />
                </aside>
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Engenharia' />
                    <List list={['Engenharia da Qualidade Integrada', 'Engenharia de Avaliações e Perícias', 'Engenharia de Estruturas', 'Engenharia Geotécnica', 'Gerência de Energia e Eficiência Energética', 'Gerenciamento de Obras na Construção Civil 4.0', 'Indústria 4.0 - Robótica e Automação Industrial']} />
                </aside>
            </div>

            {/* Outras Áreas do Conhecimento */}
            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Outras áreas de Conhecimento' />
                    <List list={['Design - EAD', 'Engenharia de Produção - EAD', 'Tecnologia em Gestão de Serviços Juridicos - EAD']} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img10} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>
        </div>
    )
}