
import teacher from '../assets/fundos/teacher-pattern.png'

interface Props {
    title: string;
}

function Title({ title }: Props) {
    return <h1 className='bg-red-600 w-fit font-bold border-8 border-white text-white text-center text-2xl md:text-3xl lg:text-5xl rounded-3xl py-4 px-7 mx-5'>{title}</h1>
}

export function Teacher() {
    return (
        <>
            <div className="max-w-screen h-screen flex flex-col gap-8 items-center justify-center overflow-hidden py-16">
                <img className='absolute -z-10 h-screen object-cover' src={teacher} alt="" />
                <Title title='Perfil dos Professores' />
                <p className='max-w-[90vw] md:max-w-[50vw] text-white text-lg md:text-xl font-medium mx-5'>
                    O nosso corpo docente é composto por executivos com excelente
                    formação acadêmica nas mais diversas áreas de aplicação da
                    gestão pública e de negócios, dedicados a treinamento, gestão,
                    consultoria, ensino e pesquisa para atualização e alavancagem
                    de novos conhecimentos para os alunos da UniAmpar.
                </p>
            </div>
        </>
    )
}