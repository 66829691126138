import lmse from '../assets/logos/logo-LMSE-fundo-escuro.png'


export function Title() {
    return <h1 className="uppercase w-fit mx-auto text-white text-center font-bold bg-green-600 py-2 px-4 md:px-8 rounded-xl text-xl md:text-2xl lg:text-3xl">Sobre a LMSE</h1>
}

export function AboutUs() {
    return (
        <div id="institucional" className="max-w-screen h-fit justify-center py-16">
            <Title />
            <div className="flex flex-col justify-center items-center md:grid grid-cols-2 bg-green-600 mt-4 p-4 max-w-[90vw] md:max-w-[80vw] mx-auto rounded-xl">
                <img src={lmse} alt="" />
                <div className='text-white text-center md:text-start flex flex-col gap-4 font-medium'>
                    <p>
                        Somos uma edtech, uma startup de educação. Não somos uma faculdade, não somos somente uma empresa de consultoria em gestão.

                        <br /><br />Somos tudo isto junto e mais: uma empresa de soluções educacionais levando educação para empresas e gestão para instituições de ensino.

                        <br /><br />Uma empresa moderna, jovem e dinâmica, criada para fechar o GAP entre teoria e prática, para unir o mundo corporativo e o acadêmico a fim de ajudar as organizações a atingirem seus objetivos estratégicos.

                        <br /><br />Criada por profissionais formados na Faculdade de Economia e Administração da Universidade de São Paulo (FEA-USP) em 2005, a então empresa Alternativa Cursos, Consultorias e Treinamentos, passou a se chamar em 2018, LMSE - Soluções Educacionais Corporativas Sob Medida com o seguinte Propósito Transformador Massivo:

                        <br /><br /><i>Ser a maior empresa de soluções educacionais corporativas inovadoras do Brasil, capaz de oferecer serviços de alta qualidade e excelência, nas áreas de: treinamentos, consultorias, assessorias e desenvolvimento humano, ajudando nossos clientes, as empresas bem como, seus parceiros de negócios a atingirem a melhor performance rumo aos seus objetivos estratégicos.</i>

                        <br /><br />Nossos valores são:
                    </p>
                    <ul className='list-disc flex flex-col items-center text-center md:items-start mx-2'>
                        <li>Ética e Honestidade</li>
                        <li>Meritocracia e senso de justiça</li>
                        <li>Liberdade de opinião e respeito à pluralidade</li>
                        <li>Empatia (foco nas pessoas)</li>
                        <li>Profissionalismo</li>
                    </ul>
                    <p>
                        Nosso slogan é: LMSE - Soluções Educacionais Corporativas Sob Medida. Integrando teoria e prática para o sucesso do seu negócio!

                        <br />Para saber mais acesse: <a href="https://www.lmse.com.br">www.lmse.com.br</a>
                    </p>
                </div>
            </div>
        </div>
    )
}