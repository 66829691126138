import { Btn } from "../components/Btn"

function SquareLeft() {
    return <div className="bg-red-600 w-[80vw] my-1/2 h-[65vh] left-0 absolute -z-10"></div>
}

function SquareRight() {
    return <div className="bg-red-600 w-[80vw] h-[65vh] absolute right-0 -z-10"></div>
}

export function Video() {
    return (
        <div id="video" className="max-w-screen h-screen flex flex-col gap-8 justify-center items-center my-8">
            <SquareLeft />
            <div className="flex flex-col justify-center items-center gap-4 max-w-[90vw]">
                <h1 className="text-white text-center text-2xl md:text-4xl uppercase font-bold">Vídeo de Apresentação dos Cursos da UniAmpar</h1>
                <iframe className="w-[90vw] h-[30vh] lg:w-[45vw] lg:h-[45vh] rounded-3xl md:p-4 bg-white" title="Conheça a Uniampar!" src="https://www.youtube.com/embed/AnvTCgxEI1E" allowFullScreen allow="autoplay"></iframe>
            </div>
            <SquareRight />
            <div className="flex flex-col justify-center items-center mx-10">
                <h2 className="text-xl text-white text-center">Deseja divulgar a Uniampar? Clique no botão abaixo para fazer o download do folder:</h2>
                <Btn href="/Folder_Online_UniAmpar.pdf" name="Download Folder" custom="-bottom-10"/>
            </div>
        </div>
    )
}