
import './App.css';

import { About } from './pages/About';
import { AboutUs } from './pages/AboutUs';
import { Bolsa } from './pages/Bolsa';
import { Contact } from './pages/Contact';
import { Course } from './pages/Course';
import { Entregavel } from './pages/Entregavel';
import { Footer } from './pages/Footer';
import { Free } from './pages/Free';
import { Header } from './pages/Header';
import { Investiment } from './pages/Investiment';
import { Subscribe } from './pages/Subscribe';
import { Teacher } from './pages/Teacher';
import { Video } from './pages/Video';

function App() {
  return (
    <>
      <Header />
      <Video />
      <About />
      <Free />
      <Course />
      <Teacher />
      <Entregavel />
      <Investiment />
      <Bolsa />
      <Subscribe />
      <AboutUs />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
