import img1 from '../assets/team/img1.png'
import img2 from '../assets/team/img2.png'
import img3 from '../assets/team/img3.png'
import img4 from '../assets/team/img4.png'
import img5 from '../assets/team/img5.png'

import { Header } from "../components/Header";
import { Title } from "../components/Title"
import { List } from "../components/List"

interface ISquare {
    position: "RIGHT" | "LEFT";
}

function Square({ position }: ISquare) {

    const style = "absolute -z-10 w-[40vw] h-[25vh] rounded-3xl"

    return <div className={
        position === "RIGHT" ? 
        `${style} bg-green-700` :
        `${style} bg-red-600`
    }></div>
}

export function Graduation() {
    return (
        <div className="max-w-screen mx-5">
            <Header title="Graduação" />

            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Educação' />
                    <List list={['Ciências Sociais - EAD', 'História', 'Letras Inglês - EAD', 'Letras Português - EAD', 'Matemática - EAD', 'Pedagogia -EAD', 'Química - EAD', 'Segunda Licenciatura Letras Pedagogia - EAD', 'Segunda Licenciatura Letras Português', 'Tradutor Intérprete - EAD',]} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img1} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>

            <div id="row" className="flex flex-col md:grid grid-cols-2 gap-4">
                <aside className='flex items-end justify-center'>
                    <img src={img2} alt="" />
                    <Square position='LEFT' />
                </aside>
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Negócios' />
                    <List list={['Tecnologia em gestão pública', 'Administração - EAD', 'Tecnologia em Comércio Exterior - EAD', 'Tecnologia em Eventos - EAD', 'Tecnologia em Gestão Comercial - EAD', 'Tecnologia em Gestão da Qualidade - EAD', 'Tecnologia em Gestão de Recursos Humanos - EAD', 'Tecnologia em Gestão Financeira - EAD', 'Tecnologia em Gestão Hospitalar - EAD', 'Tecnologia em Gestão Pública - EAD', 'Tecnologia em Logística - EAD', 'Tecnologia em Comércio Exterior - EAD', 'Tecnologia em Marketing - EAD', 'Tecnologia em Processos Gerenciais - EAD', 'Tecnologia em Secretariado - EAD', 'Turismo - EAD']} />
                </aside>
            </div>

            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Saúde' />
                    <List list={['Ciências Biológicas (Licenciatura)', 'Educação Física EAD', 'Serviço Social - EAD', 'Tecnologia em Gastronomia - EAD', 'Tecnologia em Gestão Ambiental - EAD', 'Tecnologia em Radiologia - EAD',]} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img3} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>

            <div id="row" className="flex flex-col md:grid grid-cols-2 gap-4">
                <aside className='flex items-end justify-center'>
                    <img src={img4} alt="" />
                    <Square position='LEFT' />
                </aside>
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Tecnologia' />
                    <List list={['Sistemas de Informações - EAD', 'Tecnologia em Análise e Desenvolvimento de Sistemas - EAD', 'Tecnologia em Banco de Dados - EAD', 'Tecnologia em Ciência de Dados - EAD', 'Tecnologia em Gestão da Tecnologia da informação - EAD', 'Tecnologia em Jogos Digitais - EAD', 'Tecnologia em Redes de Computadores- EAD', 'Tecnologia em Segurança da informação - EAD', 'Tecnologia em Sistemas para Internet - EAD',]} />
                </aside>
            </div>

            <div id="row" className="flex flex-col-reverse md:grid grid-cols-2 gap-4">
                <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                    <Title title='Outras áreas de Conhecimento' />
                    <List list={[ 'Design - EAD', 'Engenharia de Produção - EAD', 'Tecnologia em Gestão de Serviços Juridicos - EAD']} />
                </aside>
                <aside className='flex items-end justify-center'>
                    <img src={img5} alt="" />
                    <Square position='RIGHT' />
                </aside>
            </div>

        </div>
    )
}