import entregavel from '../assets/fundos/entregavel-pattern.png'

import { Hand, LineChart, School, GraduationCap, Star, Headphones, School2, Settings, PieChart, CircleDollarSign } from 'lucide-react'

import { CardEntregavel } from '../components/CardEntregavel'
import { ChalkboardTeacher, CrownSimple, Faders } from '@phosphor-icons/react'

export function Entregavel() {
    return (
        <div className='max-w-screen py-16 bg-gradient-to-tr from-green-500 to-green-600 lg:bg-none'>
            <img className='absolute -z-10 h-[80vh] w-screen object-fit hidden lg:block' src={entregavel} alt="" />
            <h1 className='bg-red-600 text-center uppercase w-fit mx-5 font-bold text-white text-2xl md:text-3xl lg:text-4xl py-4 px-8 md:px-12 rounded-full my-4 md:mx-auto'>Entregáveis ao Aluno</h1>

            <div className='flex flex-wrap justify-center items-center gap-8 mx-10 md:mx-[10rem]'>


                <CardEntregavel icon={School} title="Pioneirismo no Conceito de Universidade" />
                <CardEntregavel icon={Star} title='Curso sob Medida e Nota Máxima no MEC' />
                <CardEntregavel icon={Faders} title='Customização e a Criação de Conteúdos' />
                <CardEntregavel icon={ChalkboardTeacher} title='Ambiente de Aprendizagem Exclusivo' />
                <CardEntregavel icon={Headphones} title='Suporte Tecnológico Pedagógico e Acadêmico' />


                <CardEntregavel icon={GraduationCap} title='Educação Baseada em Projetos' />
                <CardEntregavel icon={Hand} title='Aprendizagem na Teoria e Prática (Hands On)' />
                <CardEntregavel icon={LineChart} title='Gestão Acadêmica por Indicadores' />
                <CardEntregavel icon={ChalkboardTeacher} title='Formação de Gestores Públicos' />
                <CardEntregavel icon={CrownSimple} title='Formação de Novas Lideranças' />


                <CardEntregavel icon={School2} title='Formação de Novos Politicos' />
                <CardEntregavel icon={Settings} title='Melhora nos Processos de Gestão Pública/Privada' />
                <CardEntregavel icon={PieChart} title='Melhoria da Qualidade/Performance na Gestão Pública' />
                <CardEntregavel icon={CircleDollarSign} title='Dedução do Investimento Realizado' />
            </div>
        </div>
    )
}