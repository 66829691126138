interface Props {
    list: string[];
}

export function List({ list }: Props) {
    return (
        <ul>
            {
                list.map((item) => (
                    <li className="list-disc font-medium">{item}</li>
                ))
            }
        </ul>
    )
}