import { CardContact } from "../components/CardContact"

import { WhatsappLogo  } from "@phosphor-icons/react"
import { Phone, Mail } from "lucide-react"

function Title () {
    return <h1 className="text-red-600 font-extrabold uppercase text-xl md:text-2xl lg:text-4xl text-center mb-10">Contato</h1>
}

function SquareRight() {
    return <div className="bg-gradient-to-tl from-red-700 to-red-500 absolute -z-20 right-0 rounded-3xl h-[80vh] w-[90vw] md:w-[70vw]"></div>
}

function SquareLeft() {
    return <div className="bg-gradient-to-tr from-green-600 to-green-400 absolute -z-10 left-0 rounded-3xl h-[80vh] w-[90vw] md:w-[70vw] mt-10"></div>
}

export function Contact() {
    return (
        <div id="contact" className="max-w-screen h-screen">
            <Title />
            <SquareRight />
            <SquareLeft />
            <div className="flex flex-wrap justify-center items-center gap-8 h-[80vh] mt-10">
                <CardContact icon={Mail} name={['Email', 'uniampar@lmse.com.br']} link="mailto:uniampar@lmse.com.br"/>
                <CardContact icon={Phone} name={['Fale conosco', '(11) 2923-9699', '(11) 2923-8695']} link="tel:112923-9699"/>
                <CardContact icon={WhatsappLogo} name={['Whatsapp', '+55 (11) 91098-5191']} link="https://web.whatsapp.com/send?phone=5511910985191"/>
            </div>
        </div>
    )
}