import img1 from '../assets/team/img15.png'
import ampar from '../assets/logos/logo_ampar.png'

export function Bolsa() {
    return (
        <div id='vestibular' className="max-w-screen h-screen flex flex-col justify-center py-16">
            <img className='mx-auto my-auto' src={ampar} alt="" />
            <div className='flex justify-around items-end bg-red-600 mt-20 h-max lg:h-[30vh] xl:h-[25vh]'>
                <div className='flex flex-col items-center justify-center gap-4 text-white py-10 mx-5'>
                    <h1 className='font-bold uppercase text-xl md:text-2xl lg:text-4xl'>Bolsa de Estudo</h1>
                    <p className='text-lg md:text-xl lg:text-2xl'>Participe do processo seletivo para concorrer a uma bolsa de estudo</p>
                    <a className='w-fit font-bold uppercase text-lg md:text-2xl border-[5px] border-white px-4 py-1 rounded-xl hover:bg-red-400 duration-300' href="https://forms.gle/MitRdjfj2uHZNZyV6">Eu quero participar!</a>
                </div>

                <img className='w-[500px] hidden lg:block' src={img1} alt="" />
            </div>
        </div>
    )
}