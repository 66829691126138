import hero from '../../assets/fundos/hero-pattern.png'


function Title() {
    return (
        <div className="text-emerald-600 bg-white text-center rounded-full py-4 px-8 w-full max-w-[50vw]">
            <h1 className='text-xl md:text-2xl lg:text-4xl uppercase font-bold'>Cursos livres, graduação, especialização e MBA</h1>
            <p>Estamos prontos para te preparar para o futuro!</p>
        </div>
    )
}

function Intro() {
    return (
        <p className="max-w-[50vw] text-center text-white text-lg">
            A Ampar dentro de sua missão de desenvolver os municípios da microrregião do Vale Paraibuna em parceria com a LMSE, uma das mais modernas Startups de Educação Corporativa 4.0, traz até você cursos de graduação, especialização/MBA, cursos livres e módulos internacionais na Europa voltados para as demandas de cada um dos municípios participantes.
            <br />São cursos nas áreas de gestão pública, negócios, educação, saúde, agricultura, tecnologia e muito mais.
        </p>
    )
}

export function Hero() {
    return (
        <div className="max-w-screen h-screen flex flex-col justify-center items-center pt-[10vh] mx-4">
            <img className='absolute -z-10 w-screen h-screen' src={hero} alt="" />
            <div className="flex flex-col gap-4 max-w-[80vw]">
                <Title />
                <Intro />
            </div>
            <a href='#video' className='absolute -bottom-32 bg-red-600 border-white border-8 text-white text-center text-3xl md:text-5xl lg:text-7xl font-sans font-bold py-7 px-12 md:py-10 md:px-20 rounded-full hover:brightness-110'>Conheça já</a>
        </div>
    )
}