import investiment from '../assets/others/investiment.png'

export function Square() {
    return <div className='absolute z-1 left-0 bg-green-700 w-[40vw] h-[30vh] rounded-r-[3rem]'></div>
}

export function Investiment() {
    return (
        <div id='finance' className="max-w-screen h-fit flex flex-col md:grid grid-cols-2 gap-16 bg-green-600 py-16">
            <aside className='flex items-end justify-center mx-5'>
                <img className='relative z-[2] w-[400px]' src={investiment} alt="" />
                <Square />
            </aside>
            <aside className='flex flex-col gap-8 justify-center items-center mx-5'>
                <h1 className='bg-green-700 py-4 px-16 border-8 border-white uppercase text-white font-bold text-xl md:text-2xl lg:text-4xl rounded-[1rem]'>Investimento</h1>
                <ul className='flex flex-col items-center md:items-start gap-4 max-w-[80vw] md:max-w-[50vw] text-center md:text-start list-disc text-white md:text-lg font-medium'>
                    <li>Curso em 24 meses sendo comporto por aulas práticas,teóricas, palestras e projetos integrador</li>
                    <li>Taxa de inscrição para o vestibular: Isento &#40;a&#41;</li>
                    <li>Planos de pagamento</li>
                    <li>Valor total do curso: R$ 6.960,00 à vista com 10% de desconto ou;</li>
                    <li>24 parcelas de R$ 322,22.</li>
                    <li>Caso o pagamento seja feito até o 5° dia útil, o aluno ganhará o desconto de pontualidade pagando R$ 290,00.</li>
                    <li>Não cobramos taxa de matrícula.</li>
                    <li>Descontos especiais para empresas do setor farmacêutico, associações e entidades de classe. Entre em contato conosco e saiba como fazer o convênio de parceria para obter os descontos.</li>
                </ul>
            </aside>
        </div>
    )
}