import { ElementType } from "react"


interface ICard {
    icon: ElementType
    name: string[]
    link: string
}

export function CardContact({ name, link, icon: Icon }: ICard) {
    return (
        <a 
            href={link}
            className="flex flex-col justify-center items-center min-w-fit w-[60vw] md:w-[11vw] h-[20vh] rounded-3xl shadow-2xl bg-white transition ease-in-out hover:-translate-y-3 hover:border-green-600 hover:border-2 duration-500">
            <Icon size={32} className="text-slate-800" />
            {
                name.map((item) => (
                    <p className="text-slate-800">{item}</p>
                ))
            }
        </a>
    )
}