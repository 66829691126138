interface IBtn {
    name: string;
    href: string;
    custom?: string;
}

export function Btn({ name, href, custom }: IBtn) {
    const DEFAULT = 'relative border-2 bg-white hover:text-white text-red-500 border-red-500 px-8 py-4 rounded-full after:block after:absolute after:z-[0] after:-bottom-10 after:-left-10 after:w-2 after:h-2 after:rounded-full hover:after:scale-[100] overflow-hidden after:bg-red-500 after:duration-1000 hover:shadow-[1px_1px_200px_#252525] hover:border-none duration-1000'
    const SPAN = 'z-10 relative font-bold'

    return <a href={`${href}`} download className={`${DEFAULT} ${custom}`}><span className={SPAN}>{name}</span></a>
}