interface Props {
    title: string;
    list: string[];
}

export function CardFree({ title, list }: Props) {
    return (
        <div className="flex flex-col justify-center p-4 bg-green-700 shadow-xl shadow-zinc-600 w-full lg:w-[20vw] h-fit min-h-[35vh] rounded-lg">
            <h1 className="text-center text-xl text-white font-bold">{title}</h1>
            <ul className="text-center text-white my-8 px-4">
                {
                    list.map((item) => (
                        <li className="list-disc">{item}</li>
                    ))
                }
            </ul>
        </div>
    )
}