import { CardFree } from "../components/CardFree"

interface IFree {
    title: string
}

function Title({ title }: IFree) {
    return (
        <h1 className="bg-green-700 px-10 py-4 border-white border-8 text-white text-center text-4xl md:text-5xl lg:text-7xl font-sans font-bold rounded-full">{title}</h1>
    )
}

export function Free() {
    return (
        <div className="max-w-screen flex flex-col items-center py-16 gap-16">
            <Title title="Cursos Livres" />
            <div className="flex flex-wrap justify-center gap-16 md:gap-[10rem] mx-8">
                <CardFree
                    title="Os Cursos propostos foram concebidos a partir das trilhas de aprendizagem:"
                    list={['Gestão e Carreira', 'Didática e Ensino', 'Regulação/Jurídico']}
                />
                <CardFree
                    title="Cursos livres - Educação Gestão e Carreira"
                    list={['Planejamento de Carreira na Educação', 'Preparação para Processos Seletivos de Mestrado', 'Relatórios Fascinantes', 'Elaboração de Portfólio para Educação Infantil utilizando o canva.com', 'Projeto de Vida Planejamento na prática', 'Documentação Pedagógica']}
                />
                <CardFree
                    title="Cursos livres – Educação Didática e Ensino"
                    list={['Metodologias Ativas de Aprendizagem', 'Gameficação na Educação Básica']}
                />
            </div>
            <div className="flex flex-wrap justify-center gap-[10rem] mx-8">
                <CardFree
                    title="Cursos livres – Educação Regulação / Jurídico"
                    list={['Bullying nas instituições de ensino', 'Repensando as relações na escola', 'Noções de Primeiros Socorros', 'Educação Especial: incluir é muito mais que receber']}
                />
                <CardFree
                    title="Cursos livres – Educação Metodologia"
                    list={['Presencial, EaD ou Híbrido', 'Síncrono ou Assíncrono', 'Carga Horária customizada', 'Conteúdo customizado', 'Plataforma própria', 'Tutoria, Suporte e Acompanhamento', 'Certificação']}
                />
            </div>
        </div>
    )
}