import { Graduation } from "./Graduation"
import { International } from "./International"
import { Ungraduation } from "./Ungraduation"

export function Course() {
    return (
        <div id="offer" className="max-w-screen h-full flex flex-col gap-16 py-16">
            <Graduation />
            <Ungraduation />
            <International />
        </div>
    )
}