import portugal from '../assets/others/portugal-flag.jpg'
import espanha from '../assets/others/espanha-flag.jpg'

export function Title() {
    return <h1 className="bg-red-600 text-white text-center px-8 py-2 uppercase text-xl md:text-2xl lg:text-4xl font-bold">Módulos Internacionais</h1>
}

export function International() {
    return (
        <div className="max-w-screen h-fit lg:h-screen flex flex-col justify-center">
            <div className="flex flex-col justify-center items-center gap-8 md:grid grid-cols-2">
                <div className='flex justify-center flex-wrap gap-4 '>
                    <img className='w-[300px] object-cover border-2 rounded-xl' src={portugal} alt="" /> 
                    <img className='w-[300px] object-cover border-2 rounded-xl' src={espanha} alt="" /> 
                </div>
                <div className='mx-5 font-medium flex flex-col gap-2'>
                    <Title />
                    <p>
                        Para dar resposta às reais necessidades do mercado, os programas de formação para executivos estruturados pela LMSE em parceria com a Porto Business School e o ISEG, o Instituto Superior de Economia e Gestão, duas das maiores escola de negócios de Portugal e a EADA uma das escolas mais reconhecidas na Espanha são disponibilizados promovendo uma aprendizagem contínua.

                        <br /><br />Este tipo de programa permite aos executivos investirem no seu desenvolvimento pessoal e profissional.

                        <br /><br />A formação de executivos destina-se a qualificar profissionais através de um curso, alinhado com os seus objetivos e desafios, que acompanha o ritmo da evolução social, econômica e empresarial.

                        <br /><br />Os profissionais terão acesso a ferramentas metodológicas e práticas para gerar valor e performance para as suas organizações em temas como:
                    </p>
                    <ul className='list-disc mx-5'>
                        <li>Estratégia e inovação</li>
                        <li>Administração geral e economia</li>
                        <li>Marketing e gestão comercial</li>
                        <li>Liderança</li>
                        <li>Tecnologia digital</li>
                        <li>Finanças</li>
                        <li>Sustentabilidade</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}